import { IIdoContract, UserConstraints } from '@/blockchainHandlers/ido-contract-interface'
import { SolanaIdoContract } from '@/blockchainHandlers/ido-contract-solana'
import { SolidityIdoContract } from '@/blockchainHandlers/ido-contract-solidity'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { UNLIMITED_ALLOCATION, Zero } from '@/constants'
import { bigNumberHelper, bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { isNumber } from 'lodash'
import { round } from 'lodash-es'
import { action, computed, IReactionDisposer, observable, runInAction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { from, Subject, Subscription, throwError } from 'rxjs'
import { concatMap, delay, retryWhen, take } from 'rxjs/operators'
import { PoolStore } from '../stores/pool-store'
import { poolsStore } from '../stores/pools-store'

export class IdoPoolSwapViewModel {
  @observable remainToken = Zero

  @observable purchasedToken = FixedNumber.from('0')
  @observable maximumToken = Zero
  @observable tradeTokenBalance = FixedNumber.from(0)

  @observable poolStore?: PoolStore

  @observable keyId = 0
  @observable approved = false
  @observable swaping = false
  @observable approving = false

  @observable forceError = ''

  @observable trade2IdoToken = false

  @observable tierId: number | undefined = undefined
  @observable currentUserTierId: number | undefined = undefined
  @observable tierTokensForSale = FixedNumber.from(0)
  @observable tierMaxUsd = FixedNumber.from(0)
  @observable isUnlimitedAllocation = false
  @observable paidFee = false

  private _diposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()
  private _lastBalanceTimer?: Subscription;

  @asyncAction *loadPool(poolId: string) {
    loadingController.increaseRequest()
    try {
      this.poolStore = yield poolsStore.cacedPoolByUnicodes[poolId]
      const contract = this.poolStore?.contract
      if (contract) {
        yield contract.init()
        this.getContractContrains()
        this.loadTradeTokenAmount(contract)
      }
    } catch (err) {
      console.error(err)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  private loadTradeTokenAmount(contract: IIdoContract) {
    this._lastBalanceTimer?.unsubscribe()
    const account = walletStore.account
    if (account) {
      this._lastBalanceTimer = from(contract.getUserTradeTokenAmount(account))
        .pipe(
          retryWhen((errors) =>
            errors.pipe(
              delay(1000),
              take(5),
              concatMap((e) => throwError(e))
            )
          )
        )
        .subscribe((balance) => {
          runInAction(() => (this.tradeTokenBalance = balance))
        })
    }
  }

  @action cancel() {
    this._lastBalanceTimer?.unsubscribe()
  }

  @asyncAction *getContractContrains() {
    loadingController.increaseRequest()
    try {
      const contract = this.poolStore?.contract
      const account = walletStore.account as any
      if (contract) {
        if (this.chain === 'sol') {
          // this.approved = false
        } else {
          this.approved = yield (contract as SolidityIdoContract).tradeErc20Contract?.isApproved(
            account,
            contract.address
          )
        }

        const { keyId, keyIdOwner, currentUserTierId, boughtAmounts, maxUserIdoAmount, userTier, paidFee } =
          (yield contract.fetchUserConstraints(account, true)) as UserConstraints
        this.keyId = keyId || 0
        this.currentUserTierId = currentUserTierId
        this.paidFee = paidFee
        this.tierId = userTier.id
        this.tierMaxUsd = userTier.maxCost
        this.isUnlimitedAllocation = bnHelper.gte(this.tierMaxUsd, UNLIMITED_ALLOCATION)
        this.tierTokensForSale = userTier.tokensForSale
        this.maximumToken = maxUserIdoAmount
        this.remainToken = userTier.tokensForSale.subUnsafe(userTier.tokensAllocated)
        this.purchasedToken = boughtAmounts

        if (this.chain === 'sol') {
          this.approved = yield (contract as SolanaIdoContract).hasCreatedUserAccount(account)
        }

        if (this.keyId > 0 && keyIdOwner?.toLowerCase() !== walletStore.account.toLowerCase())
          this.forceError = `You are not owner of NFT Card ${this.keyId}`
        else this.forceError = ''
      }
    } catch (e) {
      //
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *approve() {
    try {
      this.approving = true
      if (!this.poolStore?.contract) throw 'Contract is undefined'
      if (this.chain === 'sol') {
        const contract = this.poolStore.contract as SolanaIdoContract
        yield contract.createUserAccount(walletStore.account)
        this.approved = true
        this.getContractContrains()
      } else {
        const contract = this.poolStore.contract as SolidityIdoContract
        const finished = yield contract.isFinalized()
        if (finished) throw new Error('This project is finalized')
        if (!contract.tradeErc20Contract) throw 'tradeErc20Contract is undefined'
        yield contract.tradeErc20Contract.approve(walletStore.account, contract.address)
        this.approved = true
      }
    } finally {
      this.approving = false
    }
  }

  @asyncAction *swap(tokenAmountText: string) {
    try {
      this.swaping = true
      const contract = this.poolStore?.contract
      if (!contract) throw 'Contract is undefined'
      const finished = yield contract.isFinalized()
      if (finished) throw new Error('This project is ended')

      const tokenAmount = FixedNumber.from(tokenAmountText || '0')
      if (tokenAmount.isZero() || tokenAmount.isNegative())
        throw new Error(`"${this.tokenName}" token amount is not valid`)
      if (bigNumberHelper.gt(tokenAmount, this.maxRemainPurchaseTokens))
        throw new Error(`"${this.tokenName}" token amount must not be exceed ${this.maxRemainPurchaseTokens}`)
      if (!walletStore.account) throw 'Wallet is undefined'
      yield contract.swap(tokenAmount, walletStore.account)
      snackController.success('Invest successfully')
      try {
        yield this.poolStore?.fetchPoolInfo()
      } catch (error) {
        // ignore
      }
    } catch (e) {
      yield this.getContractContrains()
      throw e
    } finally {
      this.swaping = false
    }
  }

  async calculateBnbCost(tokenAmount = 0) {
    try {
      const contract = this.poolStore?.contract
      if (!contract) return Zero
      if (isNaN(tokenAmount)) return Zero
      return await contract.cost(FixedNumber.from(`${tokenAmount}`)).then((val) => val)
    } catch (error) {
      return Zero
    }
  }

  calculateAmountToken(bnbCost = 0) {
    try {
      if (isNaN(bnbCost)) return Zero
      return FixedNumber.from(bnbCost).divUnsafe(this.tradeValue)
    } catch (error) {
      return Zero
    }
  }

  @action.bound togglePriceShow() {
    this.trade2IdoToken = !this.trade2IdoToken
  }

  @computed get priceText() {
    if (this.trade2IdoToken) {
      const val = round(FixedNumber.from('1').divUnsafe(this.tradeValue).toUnsafeFloat(), 5)
      return `1 ${this.tradeToken} = ${val} ${this.tokenName || ''}`
    } else {
      const val = round(this.tradeValue.toUnsafeFloat(), 5)
      return `1 ${this.tokenName} = ${val} ${this.tradeToken || ''}`
    }
  }

  @computed get purchasedBnb() {
    return this.purchasedToken.mulUnsafe(this.tradeValue)
  }

  @computed get maxRemainPurchaseBnb() {
    return this.maxRemainPurchaseTokens.mulUnsafe(this.tradeValue)
  }

  @computed get maxRemainPurchaseTokens() {
    const possibleMax = this.maximumToken.subUnsafe(this.purchasedToken)
    let result: FixedNumber
    if (possibleMax.isNegative()) {
      result = FixedNumber.from('0')
    } else {
      let tradeTokenBalance = this.tradeTokenBalance
      if (!this.paidFee) {
        if (bnHelper.gt(tradeTokenBalance, this.userFee)) {
          tradeTokenBalance = tradeTokenBalance.subUnsafe(this.userFee)
        } else {
          tradeTokenBalance = FixedNumber.from('0')
        }
      }
      const maxTokenCanBuy = tradeTokenBalance.divUnsafe(this.tradeValue)
      if (bigNumberHelper.gt(possibleMax, this.remainToken)) {
        if (bigNumberHelper.gt(this.remainToken, maxTokenCanBuy)) {
          result = maxTokenCanBuy
        } else {
          result = this.remainToken
        }
      } else if (bigNumberHelper.gt(possibleMax, maxTokenCanBuy)) {
        result = maxTokenCanBuy
      } else {
        result = possibleMax
      }
    }
    return result
  }

  @computed get tradeValue() {
    return this.poolStore?.tradeValue || FixedNumber.from('1')
  }

  @computed get userFee() {
    return this.poolStore?.userFee || FixedNumber.from('5')
  }

  @computed get poolRemainedBnb() {
    return this.remainToken.mulUnsafe(this.tradeValue)
  }

  @computed get poolId() {
    return this.poolStore?.pool?.id
  }
  @computed get poolName() {
    return this.poolStore?.pool?.name
  }
  @computed get tradeToken() {
    return this.poolStore?.tradeToken
  }
  @computed get tokenName() {
    return this.poolStore?.pool?.tokenName || ''
  }
  @computed get tradeByErc20() {
    return !!this.poolStore?.tradeByErc20
  }
  @computed get enableSwap() {
    return !this.tradeByErc20 || this.approved
  }
  @computed get chain() {
    return this.poolStore?.pool?.chain
  }
  @computed get isSolana() {
    return this.poolStore?.pool?.chain === 'sol'
  }
  @computed get tierText() {
    return this.getTierText(this.tierId)
  }

  @computed get userTierError() {
    if (isNumber(this.tierId) && isNumber(this.currentUserTierId)) {
      if (this.tierId > this.currentUserTierId) {
        return `You invested this project by "${this.tierText}" tier, but you are "${this.getTierText(
          this.currentUserTierId
        )}" tier now. Please stake more to continue.`
      }
    }
    return ''
  }

  private getTierText(tierId) {
    switch (tierId) {
      case 1:
        return 'MINI BEE'
      case 2:
        return 'WORKER BEE'
      case 3:
        return 'DRONE BEE'
      case 4:
        return 'QUEEN BEE'
      default:
        return 'COMMUNITY'
    }
  }

  destroy() {
    this._diposers.forEach((d) => d())
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }
}
