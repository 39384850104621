

































































































































































import { promiseHelper } from '@/helpers/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { IdoPoolSwapViewModel } from '../viewmodels/ido-pool-swap-viewmodel'
import { WalletSignTransactionError } from '@solana/wallet-adapter-base'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

@Observer
@Component({})
export default class IdoPoolDSwap extends Vue {
  @Provide() vm = new IdoPoolSwapViewModel()

  tab = null
  bnbCost = ''
  amountToken = ''
  error = ''

  dialog = false
  resolve?: (any) => void

  async open() {
    this.dialog = true
    await promiseHelper.delay(100)
    this.vm.loadPool(this.$route.params.poolid)
    return new Promise((r) => (this.resolve = r))
  }
  async cancel(result = false) {
    this.resolve && this.resolve(result)
    this.dialog = false
    await promiseHelper.delay(100)
    this.error = ''
    this.bnbCost = ''
    this.amountToken = ''
    this.vm.cancel()
  }

  async approve() {
    try {
      this.error = ''
      await this.vm.approve()
    } catch (error) {
      if (error instanceof WalletSignTransactionError) {
        this.cancel()
      }

      snackController.error(error.msg || error.message || error)
    }
  }

  async swap() {
    try {
      this.error = ''
      await this.vm.swap(this.amountToken)
      this.cancel(true)
    } catch (error) {
      if (error instanceof WalletSignTransactionError) {
        this.cancel()
      }
      snackController.error(error.msg || error.message || error)
    }
  }

  maxBnb() {
    this.bnbCost = this.vm.maxRemainPurchaseBnb + ''
    this.userChangeBnbCost(this.bnbCost)
  }

  userChangeBnbCost($event) {
    this.error = ''
    const amountToken = this.vm.calculateAmountToken($event)
    this.amountToken = amountToken.toString()
  }

  async userChangeAmountToken($event) {
    this.error = ''
    const bnbCost = await this.vm.calculateBnbCost($event)
    this.bnbCost = bnbCost.toString()
  }
}
